const menus = document.getElementsByClassName("navegador_principal-secciones")
const redes = document.getElementsByClassName("navegador_principal-redes")
const navegador = document.getElementsByClassName("navegador_principal")
const boton = document.getElementsByClassName("icon")
const botomF = document.getElementsByClassName("boton_filtros")[0]
const divFiltros = document.getElementsByClassName("tipo_Defiltros-PorComida-filtro")
const galeria = document.getElementsByClassName("galeria")[0]
const precio = document.getElementById("max-price")
let sliderOne = document.getElementById("slider-1");
let sliderTwo = document.getElementById("slider-2");
let displayValOne = document.getElementById("range1");
let displayValTwo = document.getElementById("range2");
let minGap = 0;
let sliderTrack = document.querySelector(".slider-track");
let sliderMaxValue = document.getElementById("slider-1").max;
let i = 2
boton[0].addEventListener('click',() =>{
    navegador[0].classList.toggle('size')
})

botomF.addEventListener('click',() =>{
    divFiltros[0].classList.toggle('quitarFiltros')
    console.log(divFiltros[0])
})


$("#slider-range").slider({
    range: true, 
    min: 0,
    max: 2000,
    step: 50,
    slide: function( event, ui ) {
      $( "#min-price").html(ui.values[ 0 ]);
      
      suffix = '';
      if (ui.values[ 1 ] == $( "#max-price").data('max') ){
         suffix = '';
      }
      $( "#max-price").html(ui.values[ 1 ] + suffix);         
    }
  })
  



window.onload = function(){
    slideOne();
    slideTwo();
}

function slideOne(){
    if(parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap){
        sliderOne.value = parseInt(sliderTwo.value) - minGap;
    }
    displayValOne.textContent = `$ ${sliderOne.value}`;
    fillColor();
}
function slideTwo(){
    if(parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap){
        sliderTwo.value = parseInt(sliderOne.value) + minGap;
    }
    displayValTwo.textContent = `$ ${sliderTwo.value}`;
    fillColor();
}
function fillColor(){
    percent1 = (sliderOne.value / sliderMaxValue) * 100;
    percent2 = (sliderTwo.value / sliderMaxValue) * 100;
    sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #fe8432 ${percent1}% , #fe8432 ${percent2}%, #dadae5 ${percent2}%)`;
}